<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="コンテンツ" icon="">
				<b-field label="公開URL"
					horizontal
					custom-class=""
					>
					<p v-if="id !== ''"><a :href="redirectUrl" target="_blank">{{ redirectUrl }}</a><i class="far fa-copy ml-20" style="cursor:pointer" @click="onCtaUrlCopyClick"></i></p>
					<p v-else>保存後に発行されます</p>
				</b-field>

				<b-field label="状態"
					horizontal
					custom-class="require"
					>
					<radio-picker :options="pageStatuses" v-model="pageStatusId"></radio-picker>
				</b-field>

				<b-field label="タイトル"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'name') }"
					:message="errors.name">
					<b-input type="text" v-model="name" />
				</b-field>

				<b-field label="遷移先URL"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'ctaUrl') }"
					:message="errors.ctaUrl">
					<b-input type="text" v-model="ctaUrl" />
				</b-field>

				<!--
				<b-field label="テンプレート" custom-class="require" horizontal>
					<b-select v-model="styleId">
						<option v-for="(row, index) in styles" :key="index" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>
				</b-field>
				-->

				<b-field label="ベースカラー"
					horizontal
					class="base-color"
					custom-class="require" >
					<div class="base-color-bg" :style="{'background': baseColor}" @click="onBaseColorClick"><i class="fas fa-eye-dropper"></i></div>
					<div v-show="isColorPickerShow" class="color-picker">
						<chrome :value="baseColorTmp" @input="onColorUpdate"></chrome>
						<b-button type="is-dark" class="delete-button" icon-right="close" @click="isColorPickerShow = false"/>
					</div>
				</b-field>

				<b-field label="headタグ埋め込み"
					horizontal
					custom-class=""
					>
					<b-input type="textarea" v-model="headTag" placeholder="&lt;head&gt;タグ内の【先頭】に挿入されます"></b-input>
				</b-field>

				<b-field label="bodyタグ埋め込み"
					horizontal
					custom-class=""
					>
					<b-input type="textarea" v-model="bodyTag" placeholder="&lt;body&gt;タグ内の【最後】に挿入されます"></b-input>
				</b-field>

				<b-field label="ファーストビュー" horizontal class="mt-30">
					<div>
						<img class="preview" :src="imageBase" v-show="!fvImage" :style="{ 'max-width': previewW + 'px', 'max-height': previewH + 'px' }">
						<image-picker class="mb-10" :previewUrl="fvImage" :isLoading="isFvImageLoading" :previewH="previewH" :previewW="previewW" @file-change="onFvImageChange" @delete="onFvImageDelete"></image-picker>
					</div>
				</b-field>

				<b-field label="CTA画像" horizontal custom-class="require" class="mt-30">
					<div>
						<b-checkbox v-model="isCtaAnimation">アニメーションさせる</b-checkbox>
						<div>
							<img class="preview" :src="imageBase" v-show="!ctaImage" :style="{ 'max-width': previewW + 'px', 'max-height': previewH + 'px' }">
							<image-picker class="mb-10" :isDeleteShow="false" :previewUrl="ctaImage" :isLoading="isCtaImageLoading" :previewH="previewH" :previewW="previewW" @file-change="onCtaImageChange"></image-picker>
							<p v-show="errors.ctaImage" class="has-text-danger">{{ errors.ctaImage }}</p>
						</div>
					</div>
				</b-field>

				<b-field label="アフター画像" horizontal class="mt-30">
					<div>
						<img class="preview" :src="imageBase" v-show="!afterImage" :style="{ 'max-width': previewW + 'px', 'max-height': previewH + 'px' }">
						<image-picker class="mb-10" :previewUrl="afterImage" :isLoading="isAfterImageLoading" :previewH="previewH" :previewW="previewW" @file-change="onAfterImageChange" @delete="onAfterImageDelete"></image-picker>
					</div>
				</b-field>

				<b-field label="質問"
					horizontal
					class="mt-50"
					>
					<b-button type="is-info" @click="onQuestionAddClick">追加</b-button>
				</b-field>

				<b-field label=""
					horizontal
					custom-class=""
					>

					<ul class="question">
						<li v-for="(question, index) in questions" :key="question.id" class="question-wrapper">
							<div class="question-contents">
								<div class="question-header">
									<p class="question-title">Q.{{ index + 1 }}</p>
									<div class="question-controller">
										<contents-controller :index="question.id" @up="onQuestionUpClick" @down="onQuestionDownClick" @delete="onQuestionDeleteClick"></contents-controller>
									</div>
								</div>

								<div class="question-body">
									<div class="question-form_field">
										<div>
											<img class="preview" :src="imageBase" v-show="!question.image" :style="{ 'max-width': previewW + 'px', 'max-height': previewH + 'px' }">
											<image-picker class="mb-10" :previewUrl="question.image" :index="question.id" :isLoading="question.isImageLoading" :previewH="previewH" :previewW="previewW" @file-change="onQuestionImageChange" @delete="onQuestionImageDelete"></image-picker>
										</div>
									</div>

									<div class="question-form_field">
										<div class="question-form_title"><label class="require">タイプ</label></div>
										<div class="question-form_input">
											<b-select v-model="question.questionTypeId">
												<option v-for="(row, index) in questionTypes" :key="index" :value="row.id">
													{{ row.name }}
												</option>
											</b-select>
										</div>
									</div>

									<div class="question-form_field">
										<div class="question-form_input">
											<b-field label="質問内容"
												custom-class="require"
												:type="{ 'is-danger': getQuestionErrorMsg(question.id) !== '' }"
												:message="getQuestionErrorMsg(question.id)"
												>
												<b-input type="text" v-model="question.text" />
											</b-field>
										</div>
									</div>

									<div class="question-form_field">
										<div class="question-form_title"><label class="require">回答</label></div>
										<div class="question-form_input">
											<b-button type="is-info" @click="onAnswerAddClick(question.id)">追加</b-button>

											<ul class="answer">
												<li v-for="answer in question.answers" :key="answer.id" class="answer-wrapper">
													<div class="answer-input">
														<b-input type="text" v-model="answer.text" />
													</div>
													<contents-controller :index="answer.id" :parentIndex="question.id" @up="onAnswerUpClick" @down="onAnswerDownClick" @delete="onAnswerDeleteClick"></contents-controller>
												</li>
											</ul>
										</div>
									</div>

									<div class="question-form_field">
										<div class="question-form_input">
											<b-field label="注釈">
												<b-input type="textarea" v-model="question.notice" />
											</b-field>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</b-field>

				<b-field horizontal class="form-buttons">
					<b-field grouped>
						<div class="control">
							<b-button @click="$router.push({ 'name': 'lp' })">一覧に戻る</b-button>
						</div>
						<div class="control">
							<b-button type="is-primary" :loading="isLoading" @click="onSubmitClick">保存</b-button>
						</div>
					</b-field>
				</b-field>
			</card-component>
		</section>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_mixins.scss';
@import '../scss/_vars.scss';
.base-color {

	&-bg {
		@include flex(center, center);
		width: 60px;
		height: 60px;
		display: flex;
		border: $commonBorder;
	}

	::v-deep .field-body {
		position: relative;
		z-index: 1;
	}

	::v-deep .field-body .field:nth-child(2) {
		position: absolute;
		top: 0;
		left: 70px;
	}

	::v-deep .field-body .field:nth-child(2) .color-picker {
		@include flex(normal, flex-start);
	}
}
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import RadioPicker from '@/components/RadioPicker'
import ImagePicker from '@/components/ImagePicker'
import ContentsController from '@/components/ContentsController'
import { v4 as uuidv4 } from 'uuid'
import { Chrome } from 'vue-color'

export default {
	name: 'LpEdit',
	components: {
		RadioPicker,
		CardComponent,
		TitleBar,
		ImagePicker,
		ContentsController,
		Chrome,
	},
	data () {
		return {
			id: '',
			name: '',
			bodyTag: '',
			headTag: '',
			ctaUrl: '',
			pageStatusId: null,
			styleId: null,
			pageStatuses: {},
			styles: [],
			questionTypes: [],
			fvImage: '',
			afterImage: '',
			imageBase: '',
			afterImageBase: '',
			baseColor: '',
			baseColorTmp: '',
			ctaImage: '',
			isCtaAnimation: false,
			isLoading: false,
			isFvImageLoading: false,
			isCtaImageLoading: false,
			isAfterImageLoading: false,
			questions: [],
			errors: {
				name: '',
				ctaUrl: '',
				ctaImage: '',
			},
			questionErrors: [],
			previewW: 240,
			previewH: 200,
			redirectUrl: '',
			isColorPickerShow: false,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		}
	},
	mounted() {
		this.init();
	},
	watch: {
		'$route' (to, from) {
			this.init();
		}
	},
	methods: {
		onBaseColorClick: function() {
			this.baseColorTmp = this.baseColor;
			this.isColorPickerShow = true;
		},
		onColorUpdate: function(value) {
			//this.$utils.log('onColorUpdate');
			//this.$utils.log(value.hex);
			this.baseColor = value.hex;
		},
		onCtaUrlCopyClick: async function() {
			try {
				await navigator.clipboard.writeText(this.redirectUrl);
				this.$utils.toastSuccess('URLをコピーしました');
			} catch (error) {
			}
		},
		onSubmitClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			for (let i in this.questions) {
				this.questions[i].errorMessage = '';

				for (let k in this.questions[i].answers) {
					this.questions[i].answers[k].errorMessage = '';
				}
			}

			if (!this.isValid()) {
				this.$utils.toastError('入力エラーがあります');
				return false;
			}

			this.isLoading = true;

			let questionsData = [];

			for (let i = 0; i < this.questions.length; i++) {
				let answersData = [];
				for (let k = 0; k < this.questions[i].answers.length; k++) {
					answersData.push({
						"text": this.questions[i].answers[k].text,
						"ordering": k + 1,
					});
				}
				questionsData.push({
					"question_type_id": this.questions[i].questionTypeId,
					"text": this.questions[i].text,
					"notice": this.questions[i].notice,
					"image": this.questions[i].image,
					"ordering": i + 1,
					"answers": answersData,
				});
			}

			let data = {
				"id": this.id,
				"name": this.name,
				"cta_url": this.ctaUrl,
				"body_tag": this.bodyTag,
				"head_tag": this.headTag,
				"style_id": this.styleId,
				"fv_image": this.fvImage,
				"after_image": this.afterImage,
				"cta_image": this.ctaImage,
				"is_cta_animation": this.isCtaAnimation,
				"base_color": this.baseColor,
				"page_status_id": this.pageStatusId,
				"questions": questionsData,
			};

			this.$utils.log(data);

			try {
				let ep = 'pages';

				if (!this.id) {
					let res = await this.$api.post(ep, data, true);
					this.$utils.log(res.data);
					this.$utils.toastSuccess('データを保存しました');
					this.$router.push({ name: 'lp-edit', params: { lpId: res.data.id } });
				} else {
					await this.$api.put(ep + '/' + this.id, data, true);
					this.$utils.toastSuccess('データを更新しました');
				}
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		isValid: function() {
			let isValid = true;

			if (this.name === '') {
				this.errors.name = this.$consts.MSG_INPUT_ERROR_REQUIRED;
				isValid = false;
			}

			if (this.ctaUrl === '') {
				this.errors.ctaUrl = this.$consts.MSG_INPUT_ERROR_REQUIRED;
				isValid = false;
			}

			if (this.ctaImage === '') {
				this.errors.ctaImage = this.$consts.MSG_INPUT_ERROR_REQUIRED;
				isValid = false;
			}

			for (let i in this.questions) {
				if (this.questions[i].text === '') {
					this.questions[i].errorMessage = this.$consts.MSG_INPUT_ERROR_REQUIRED;
					isValid = false;
				}

				for (let k in this.questions[i].answers) {
					if (this.questions[i].answers[k].text === '') {
						this.questions[i].answers[k].errorMessage = this.$consts.MSG_INPUT_ERROR_REQUIRED;
						isValid = false;
					}
				}
			}

			return isValid;
		},
		getQuestionErrorMsg: function(questionId) {
			let i = this.questions.findIndex((v) => v.id === questionId);
			return this.questions[i].errorMessage;
		},
		getAnswerErrorMsg: function(questionId, answerId) {
			let questionIndex = this.questions.findIndex((v) => v.id === questionId);
			let answerIndex = this.questions[questionIndex].answers.findIndex((v) => v.id === answerId);
			return this.questions[questionIndex].answers[answerIndex].errorMessage;
		},
		replaceIndex: function(array, index) {
			//this.$utils.log('replaceIndex');
			//this.$utils.log(array);
			//this.$utils.log(index);
			array.splice(index - 1, 2, array[index], array[index - 1]);
		},
		onQuestionUpClick: function(id) {
			let i = this.questions.findIndex((v) => v.id === id);
			if (i === 0) return;
			this.replaceIndex(this.questions, i);
		},
		onQuestionDownClick: function(id) {
			let i = this.questions.findIndex((v) => v.id === id);
			if (i === this.questions.length - 1) return;
			this.replaceIndex(this.questions, i + 1);
		},
		onQuestionDeleteClick: function(id) {
			let i = this.questions.findIndex((v) => v.id === id);
			if (!confirm(`Q.${i + 1}を削除します。よろしいですか？`)) return false;
			this.questions.splice(i, 1);
		},
		onQuestionAddClick: function() {
			this.questions.push({
				id: uuidv4(),
				text: '',
				questionTypeId: 1,
				image: '',
				isImageLoading: false,
				errorMessage: '',
				answers: [],
			});
		},
		onAnswerUpClick: function(answerId, questionId) {
			let questionIndex = this.questions.findIndex((v) => v.id === questionId);
			let answerIndex = this.questions[questionIndex].answers.findIndex((v) => v.id === answerId);
			if (answerIndex === 0) return;
			this.replaceIndex(this.questions[questionIndex].answers, answerIndex);
		},
		onAnswerDownClick: function(answerId, questionId) {
			let questionIndex = this.questions.findIndex((v) => v.id === questionId);
			let answerIndex = this.questions[questionIndex].answers.findIndex((v) => v.id === answerId);
			if (answerIndex === this.questions[questionIndex].answers.length - 1) return;
			this.replaceIndex(this.questions[questionIndex].answers, answerIndex + 1);
		},
		onAnswerDeleteClick: function(answerId, questionId) {
			let questionIndex = this.questions.findIndex((v) => v.id === questionId);
			let answerIndex = this.questions[questionIndex].answers.findIndex((v) => v.id === answerId);
			this.questions[questionIndex].answers.splice(answerIndex, 1);
		},
		onAnswerAddClick: function(questionId) {
			let i = this.questions.findIndex((v) => v.id === questionId);
			this.questions[i].answers.push({
				id: uuidv4(),
				text: '',
				errorMessage: '',
			});
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let params = this.$route.params.lpId ? '/' + this.$route.params.lpId : '';
			let ep = 'pages/screen' + params;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.id = res.data.id;
				this.pageStatusId = res.data.page_status_id;
				this.name = res.data.name;
				this.fvImage = res.data.fv_image;
				this.afterImage = res.data.after_image;
				this.ctaImage = res.data.cta_image;
				this.isCtaAnimation = res.data.is_cta_animation;
				this.baseColor = res.data.base_color;
				this.imageBase = res.data.image_base;
				this.ctaUrl = res.data.cta_url;
				this.styleId = res.data.style_id;
				this.bodyTag = res.data.body_tag;
				this.headTag = res.data.head_tag;
				this.redirectUrl = res.data.redirect_url;

				this.styles = res.data.styles;
				this.pageStatuses = res.data.page_statuses;
				this.questionTypes = res.data.question_types;

				let questions = res.data.questions;
				if (this.id !== '' && questions.length > 0) {
					for (let i = 0; i < questions.length; i++) {
						let answers = [];
						for (let k = 0; k < questions[i].answers.length; k++) {
							answers.push({
								id: questions[i].answers[k].id,
								text: questions[i].answers[k].text,
								errorMessage: '',
							});
						}

						this.questions.push({
							id: questions[i].id,
							text: questions[i].text,
							notice: questions[i].notice,
							questionTypeId: questions[i].question_type_id,
							image: questions[i].image,
							isImageLoading: false,
							errorMessage: '',
							answers: answers,
						});
					}
				}
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onCtaImageChange: function(file) {
			let self = this;
			this.isCtaImageLoading = true;
			this.uploadImage(file, function(url) {
				self.ctaImage = url;
			});
		},
		onFvImageDelete: function() {
			this.fvImage = '';
		},
		onFvImageChange: function(file) {
			let self = this;
			this.isFvImageLoading = true;
			this.uploadImage(file, function(url) {
				self.fvImage = url;
			});
		},
		onAfterImageDelete: function() {
			this.afterImage = '';
		},
		onAfterImageChange: function(file) {
			let self = this;
			this.isAfterImageLoading = true;
			this.uploadImage(file, function(url) {
				self.afterImage = url;
			});
		},
		onQuestionImageDelete: function(id) {
			let i = this.questions.findIndex((v) => v.id === id);
			this.questions[i].image = '';
		},
		onQuestionImageChange: function(file, id) {
			let self = this;
			let i = this.questions.findIndex((v) => v.id === id);
			this.questions[i].isImageLoading = true;
			this.uploadImage(file, function(url) {
				self.questions[i].image = url;
			});
		},
		uploadImage: async function(file, callback) {
			let data = {
				"image": file,
			};
			let ep = 'pages/image';

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				callback(res.data.url);
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				// 全画像ローディングを非表示
				this.isCtaImageLoading = false;
				this.isFvImageLoading = false;
				this.isAfterImageLoading = false;
				for (let i in this.questions) {
					this.questions[i].isImageLoading = false;

					for (let k in this.questions[i].answers) {
						this.questions[i].answers[k].isImageLoading = false;
					}
				}
			}
		},
	}
}
</script>
