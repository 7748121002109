<template>
	<div>
		<b-button pack="fas" icon-right="arrow-up" @click="onUpClick"/>
		<b-button class="mr-10" pack="fas" icon-right="arrow-down"  @click="onDownClick"/>
		<b-button type="is-danger" icon-right="delete"  @click="onDeleteClick"/>
	</div>
</template>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";

.preview {
	height: auto;
	border: $commonBorder;
}

::v-deep .button {
	width: 40px !important;
}
</style>

<script>

export default {
	name: 'ContentsController',
	props: {
		index: {
			type: String,
			default: '',
		},
		parentIndex: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	methods: {
		onUpClick: function() {
			this.execEmit('up');
		},
		onDownClick: function() {
			this.execEmit('down');
		},
		onDeleteClick: function() {
			this.execEmit('delete');
		},
		execEmit: function(key) {
			if (this.parentIndex === '') {
				this.$emit(key, this.index);
			} else {
				this.$emit(key, this.index, this.parentIndex);
			}
		},
	},
}
</script>
